import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Helmet } from 'react-helmet';

const About = () => {
  const { slug } = useParams();
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    const filePath = `${process.env.PUBLIC_URL}/content/about/about.md`;

    fetch(filePath)
      .then((response) => response.text())
      .then((text) => setMarkdownContent(text))
      .catch((error) => console.error('It stopped working:', error));
  }, [slug]);
  useEffect(() => {
    
    
  }, [markdownContent]);

  return (

    <div>
      <Helmet>
        <title>About Holland</title>
        <meta name="description" content="Sam Holland, art director." />
        <meta name="keywords" content="art director, advertising, providence, boston, new england, northeast, hey even new york, freelance, graphic design, photography, motion graphics, dr timothy tomasso, etc" />
      </Helmet>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={markdownContent} />
    </div>
    );
};

export default About;
