import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { extractImageUrls } from '../utils/extractImageUrls';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Helmet } from 'react-helmet';

const Project = () => {
  const { slug } = useParams();
  const [markdownContent, setMarkdownContent] = useState('');
  const [lightboxImage, setLightboxImage] = useState(null);

  const VideoPlayer = ({ src }) => {
    return (
      <video src={src} autoplay loop muted playsinline></video>
    );
  };
  useEffect(() => {
    const filePath = `${process.env.PUBLIC_URL}/content/${slug}.md`;

    fetch(filePath)
      .then((response) => response.text())
      .then((text) => setMarkdownContent(text))
      .catch((error) => console.error('Error fetching markdown:', error));
  }, [slug]);

   const LightboxImage = ({ src, alt, className }) => {
    const [lightboxOpen, setLightboxOpen] = useState(false);

    const handleClick = () => {
      setLightboxOpen(!lightboxOpen);
    };

    return (
      <>
        <img src={src} alt={alt} className={className} onClick={handleClick} />
        {lightboxOpen && (
          <div className="lightbox-overlay">
            <div className="lightbox-content">
              <img src={src} alt={alt} className="lightbox-image" onClick={handleClick} />
              <p className="lightbox-caption">{alt}</p>
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    const carouselContainer = document.querySelector('.carousel-container');

    if (carouselContainer) {
      const images = Array.from(carouselContainer.querySelectorAll('img'));
      const carousel = (
        <Carousel centerMode infiniteLoop={true} emulateTouch={true} showIndicators={false} showThumbs={false} >
          {images.map((image, index) => (
            <div key={index}>
              <img src={image.src} key="carouselKey" alt={image.alt} />
            </div>
          ))}
        </Carousel>
      );
      ReactDOM.render(carousel, carouselContainer);
    }
  }, [markdownContent]);

  return (
    <div>
      <Helmet>
        <title>Holland - Work</title>
        <meta name="description" content="Sam Holland, art director." />
        <meta name="keywords" content="art director, advertising, providence, boston, new england, northeast, hey even new york, freelance, graphic design, photography, motion graphics, dr timothy tomasso, etc" />
      </Helmet>
      <div>
        <ReactMarkdown  components={{ img: LightboxImage, VideoPlayer }} rehypePlugins={[rehypeRaw]} children={markdownContent} />
      </div>
    </div>
  );
};

export default Project;
