import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Work from './components/Work';
import Store from './components/Store';
import About from './components/About';
import Contact from './components/Contact';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Project from './components/Project';
import Events from './components/Events';
import Item from './components/Item';
import LibraryPage from './components/LibraryPage';
import Mockery from './components/Mockery';
import './App.css';


const App = () => {

  function preloadImage(url) {
    const img = new Image();
    img.src = url;
  }

  preloadImage('/images/psnc-rich-drama/psnc_img.jpg');
  preloadImage('/images/rhode-island-blood-center/ribc_img.jpg');
  preloadImage('/images/lifespan-what-we-get/lifespan_img.jpg');
  preloadImage('/images/ridoh-dont-tempt-2020/ridoh_img.jpg');

  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/work" element={<Work />} />
        <Route path="/work/:slug" element={<Project />} />
        <Route path="/store" element={<Store />} />
        <Route path="/store/:slug" element={<Item />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/events" element={<Events />} />
        <Route path="/video-store" element={<LibraryPage />} />
        <Route path="/mockery" element={<Mockery />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;