import React, { useState, useEffect, useRef } from 'react';
import '../mockery.css';
import defaultVideo from '../images/default-video-2.mp4';
import defaultAvatar from '../images/default-avatar.jpg';
import defaultIcon from '../images/mockery-icon-placeholder.jpg';
import likesIcon from '../images/mockery-likes.svg';
import commentsIcon from '../images/mockery-comments.svg';
import bookmarksIcon from '../images/mockery-bookmarks.svg';
import sharesIcon from '../images/mockery-shares.svg';
import overlayImage from '../images/IMG_9400.png';
import iphoneFrame from '../images/mockery-iphone-frame.png';
import tiktokMenu from '../images/mockery-tiktokMenu.png';



const Mockery = () => {
  // State variables for form inputs and video
  const [likes, setLikes] = useState(0);
  const [comments, setComments] = useState(0);
  const [bookmarks, setBookmarks] = useState(0);
  const [shares, setShares] = useState(0);
  const [accountName, setAccountName] = useState('');
  const [headline, setHeadline] = useState('Headline');
  const [postText, setPostText] = useState('');
  const [avatar, setAvatar] = useState(defaultAvatar);
  const [video, setVideo] = useState(null);
  const videoRef = useRef(null);
  const [brightness, setBrightness] = useState(100);
  const [contrast, setContrast] = useState(100);
  const [shadowOpacity, setShadowOpacity] = useState(0.5);
  const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // Initial background color is white
  const [videoPosition, setVideoPosition] = useState(0); // Initial video position



   const handleAccountNameChange = (e) => {
    setAccountName(e.target.value);
  };

  const handleHeadlineChange = (e) => {
    setHeadline(e.target.value);
  };

  const handlePostTextChange = (e) => {
    setPostText(e.target.value);
  };

   const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'accountName') {
      setAccountName(value);
    } else if (name === 'postText') {
      setPostText(value);
    }
  };

  const getPlaceholderText = (inputValue, placeholderText) => {
    return inputValue ? inputValue : placeholderText;
  };

  const handleBrightnessChange = (e) => {
    setBrightness(e.target.value);
  };

  const handleContrastChange = (e) => {
    setContrast(e.target.value);
  };

  const handleOpacityChange = (e) => {
    setShadowOpacity(e.target.value);
  };

  const handleColorChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  const handlePositionChange = (e) => {
    setVideoPosition(parseInt(e.target.value, 10)); // Parse the slider value to an integer
  };

  const getTextContrastColor = (bgColor) => {
    // Convert the background color to HSL
    const tempDiv = document.createElement('div');
    tempDiv.style.backgroundColor = bgColor;
    document.body.appendChild(tempDiv);
    const computedStyle = getComputedStyle(tempDiv);
    const hslColor = computedStyle.backgroundColor;
    document.body.removeChild(tempDiv);

    // Extract the lightness component from the HSL color
    const [, lightness] = hslColor.match(/\d+/g).map(Number);

    // Determine the contrast color based on the lightness threshold (50 in this example)
    return lightness < 50 ? '#ffffff' : '#18161d';
  };

  useEffect(() => {
    // Retrieve stored values from localStorage and update state
    const storedAvatar = localStorage.getItem('avatar');
    if (storedAvatar) {
      setAvatar(storedAvatar);
    }
    const storedLikes = localStorage.getItem('likes');
    if (storedLikes) {
      setLikes(parseInt(storedLikes));
    }
    const storedComments = localStorage.getItem('comments');
    if (storedComments) {
      setComments(parseInt(storedComments));
    }
    const storedBookmarks = localStorage.getItem('bookmarks');
    if (storedBookmarks) {
      setBookmarks(parseInt(storedBookmarks));
    }
    const storedShares = localStorage.getItem('shares');
    if (storedShares) {
      setShares(parseInt(storedShares));
    }
    const storedAccountName = localStorage.getItem('accountName');
    if (storedAccountName) {
      setAccountName(storedAccountName);
    }
    const storedHeadline = localStorage.getItem('headline');
    if (storedHeadline) {
      setHeadline(storedHeadline);
    }
    const storedPostText = localStorage.getItem('postText');
    if (storedPostText) {
      setPostText(storedPostText);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Save form values to localStorage
    localStorage.setItem('avatar', avatar);
    localStorage.setItem('likes', likes.toString());
    localStorage.setItem('comments', comments.toString());
    localStorage.setItem('bookmarks', bookmarks.toString());
    localStorage.setItem('shares', shares.toString());
    localStorage.setItem('accountName', accountName);
    localStorage.setItem('headline', headline);
    localStorage.setItem('postText', postText);

    // Handle video upload and other logic
  };

  const handleAvatarChange = (e) => {
	  const file = e.target.files[0]; // Get the selected file
	  if (file) {
	    const reader = new FileReader();
	    reader.onload = () => {
	      const dataURL = reader.result;
	      setAvatar(dataURL); // Update the avatar state with the data URL of the selected file
	    };
	    reader.readAsDataURL(file);
	  }
	};

  const handleVideoChange = (e) => {
    const file = e.target.files[0]; // Get the selected video file
    setVideo(file); // Update the video state with the file object
  };
  
  const formatNumber = (number) => {
	  if (number >= 1000 && number < 1000000) {
	    // Convert to K format (e.g., 1K)
	    return (number / 1000).toFixed(1) + 'K';
	  } else if (number >= 1000000) {
	    // Convert to M format (e.g., 1.2M)
	    return (number / 1000000).toFixed(1) + 'M';
	  } else {
	    // Return the original number
	    return number.toString();
	  }
	};

  useEffect(() => {
    if (!video) {
      // Load the default video when the component mounts
      videoRef.current.src = defaultVideo;
    } else {
      // Handle video playback when a new video is selected
      videoRef.current.src = URL.createObjectURL(video);
    }
  }, [video]);

  useEffect(() => {
    // Autoplay the video when it's ready
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, [videoRef.current]);


  useEffect(() => {
    // Generate random numbers for number fields
    const generateRandomNumber = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    setLikes(generateRandomNumber(1000, 2500));
    setComments(generateRandomNumber(100, 800));
    setShares(generateRandomNumber(1000, 5000));
    setBookmarks(generateRandomNumber(1000, 500));
  }, []);

  return (
  	<div className="m_container"
        style={{ backgroundColor, color: getTextContrastColor(backgroundColor) }}>
	    <div class="m_mockeryWrap">

	     

	      <form onSubmit={handleSubmit} class="m_form">
	       	<h2>Mockery</h2>
	       	<div>
	       	<div class="m_uploadVideo">
	       		<p>Upload a video:</p>
	       		<br />
	          <input type="file" accept="video/*" onChange={handleVideoChange} />
	        </div>
	        </div>
	        <br />
		     <div>
		        <label>Avatar:</label>
				<input type="file" onChange={handleAvatarChange} />
			</div>

	        <div>
			<div>
	        <label>
	          Account:</label>
	           <input
	           	className="m_text_input"
	            type="text"
		        name="accountName"
		        value={accountName}
		        onChange={handleInputChange}
		        placeholder="Account Name"
		        onFocus={(e) => e.target.select()}
	          />
	        </div>
	        
	        <div>


	        <label>
	          Post:</label>
	          <textarea
	          	className="m_text_input"
	            name="postText"
		        value={postText}
		        onChange={handleInputChange}
		        placeholder="Write your post here..."
		        onFocus={(e) => e.target.select()}
	          />
	        </div>
	        <br />
	        <p>Fake engagement:</p>
	        <br />
			<div>
		        <label>Likes:</label>
		          <input
		          	className="m_numeric_input"
		            type="number"
		            value={likes}
		            onChange={(e) => setLikes(parseInt(e.target.value))}
		          />
	        </div>
	        <div>
	        <label>Comments:</label>
	          <input
	          	className="m_numeric_input"
	            type="number"
	            value={comments}
	            onChange={(e) => setComments(parseInt(e.target.value))}
	          />
	        </div>
	        <div>
	        <label>Bookmarks:</label>
	          <input
	          	className="m_numeric_input"
	            type="number"
	            value={bookmarks}
	            onChange={(e) => setBookmarks(parseInt(e.target.value))}
	          />
	        </div>
	        <div>
	        <label>Shares:</label>
	          <input
	          	className="m_numeric_input"
	            type="number"
	            value={shares}
	            onChange={(e) => setShares(parseInt(e.target.value))}
	          />
	        </div>
	        <br />
	        <p>Adjust the video:</p>
	        <br />
	        <label htmlFor="brightness-slider">Brightness:</label>
	      <input
	        type="range"
	        id="brightness-slider"
	        value={brightness}
	        onChange={handleBrightnessChange}
	        min="0"
	        max="200"
	      />

	      {/* Contrast Slider */}
	      <label htmlFor="contrast-slider">Contrast:</label>
	      <input
	        type="range"
	        id="contrast-slider"
	        value={contrast}
	        onChange={handleContrastChange}
	        min="0"
	        max="200"
	      />

	      <label htmlFor="opacity-slider">Shadow:</label>
	      <input
	        type="range"
	        id="opacity-slider"
	        value={shadowOpacity}
	        onChange={handleOpacityChange}
	        min="0"
	        max="1"
	        step="0.01"
	      />
	      <label htmlFor="position">Position</label>
	      <input
		    type="range"
		    min={-2000} // Set the minimum value (e.g., -100)
		    max={1000} // Set the maximum value (e.g., 100)
		    value={videoPosition}
		    onChange={handlePositionChange}
		  />

	      <label htmlFor="color">Background:</label>
	      <input
		    type="color"
		    value={backgroundColor}
		    onChange={handleColorChange}
		  />
		  
	      </div>
	        
	      </form>
	      <div class="m_mockup">
	      	
		      <div class="m_phoneWrap">
		      	<div class="m_phoneInner">
			      <div class="m_mockeryFrame" style={{
			        backgroundImage: `url(${iphoneFrame})`,
			        backgroundSize: 'cover',
			      }}></div>
		        {/* Render the HTML representation of the form inputs */}
			    <div class="m_phoneContent">
			      	<div class="video_container">
			          <video ref={videoRef} autoPlay muted loop
			          style={{
				          filter: `brightness(${brightness}%) contrast(${contrast}%)`,
				          transform: `translateX(${videoPosition}px)`
				        }}>
		          	  	<source src={defaultVideo} type="video/mp4" />
		               Your browser does not support the video tag.
		        	  </video>
		        	  <div class="m_shadowOverlay" style={{ opacity: shadowOpacity }}></div>
			        </div>
			        <div class="m_ui">
			        	<img src={overlayImage} className="m_overlayLayoutAssist" />
				        <div class="m_top">
				        	<ul class="m_topMenu">
				        		<li><span class="live">live</span></li>
				        		<li>Following</li>
				        		<li><span class="selected">For You</span></li>
				        		<li><span class="search">Search</span></li>
				        	</ul>
				        </div>
				        <div class="m_leftSidebar">
					      <div>
					        <img src={avatar} alt="Avatar" className="m_sidebarAvatar" />
					      </div>
					      <div> <img src={likesIcon} className="m_sidebarIcon" alt="Icon" /><p><span>Likes: </span>{formatNumber(likes)}</p></div>
					      <div><img src={commentsIcon} className="m_sidebarIcon" alt="Icon" /><p><span>Comments: </span>{formatNumber(comments)}</p></div>
				          <div><img src={bookmarksIcon} className="m_sidebarIcon" alt="Icon" /><p><span>Bookmarks: </span>{formatNumber(bookmarks)}</p></div>
				          <div><img src={sharesIcon} className="m_sidebarIcon" alt="Icon" /><p><span>Shares: </span>{formatNumber(shares)}</p></div>
					    </div>
					    <div className="m_bottomBar">
					        <div><h3>{accountName}</h3></div>
					        <div><p>{postText}</p></div>
				        </div>

				      </div>
				      <div class="m_tiktokMenu">
				      	<img src={tiktokMenu} />
				      </div>
			      </div>

			    </div>
		      </div>
	      </div>
	     </div>
     </div>
  );
};

export default Mockery;
