import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useLocation } from 'react-router-dom';
import ActiveRouteName from './ActiveRouteName';

const Navigation = () => {
  const [scrolledPast80vh, setScrolledPast80vh] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const scrolledPast = scrollPosition > windowHeight * 0.7;

      setScrolledPast80vh(scrolledPast);
    };


    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className={`navbarWrapper ${ActiveRouteName()}`}>
      <nav className={`navbar${scrolledPast80vh ? ' scrolled' : ''}`}>
        <NavLink className="navText" to="/work" activeClassName="active" end>Work</NavLink>
        <NavLink className="homeBrand" to="/"><span> HOLLAND </span></NavLink>
        <NavLink className="navText" to="/about">Info</NavLink>
      </nav>
    </div>
  );
};

export default Navigation;