import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Work = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTags, setSelectedTags] = useState(() => {
    const query = new URLSearchParams(location.search);
    const tag = query.get('tag') || 'all';
    return [tag];
  });

  useEffect(() => {
    const query = new URLSearchParams();
    if (selectedTags[0] !== 'all') {
      query.set('tag', selectedTags[0]);
    }
    navigate({ search: query.toString() }, { replace: true });
  }, [selectedTags, navigate]);

  const handleTagClick = (tag) => {
    setSelectedTags([tag]);
  };


  const projects = [
    { id: 1, title: 'Rich Drama', client: 'Newport Mansions', tags: 'professional campaign concept art-direction', image: '/images/psnc-rich-drama/psnc_img.jpg', slug: 'psnc-rich-drama' },
    { id: 2, title: 'Help Someone Else', client: 'Rhode Island Blood Center', tags: 'professional campaign concept copywriting art-direction', image: '/images/rhode-island-blood-center/ribc_img.jpg', slug: 'rhode-island-blood-center' },
    { id: 3, title: 'What We Get', client: 'Lifespan', tags: 'professional campaign concept art-direction', image: '/images/lifespan-what-we-get/lifespan_img.jpg', slug: 'lifespan-what-we-get' },
    { id: 4, title: 'Don’t Tempt 2020', client: 'Rhode Island Department of Health', tags: 'professional campaign design illustration concept copywriting art-direction motion-graphics', image: '/images/ridoh-dont-tempt-2020/ridoh_img.jpg', slug: 'ridoh-dont-tempt-2020' },
    { id: 5, title: 'Migrant Mother', client: 'ASMP', tags: 'professional campaign concept copywriting', image: 'images/asmp-migrant-mother/asmp_img.jpg', slug: 'asmp-migrant-mother' },
    { id: 6, title: 'Childhood Disease', client: 'Tufts Childrens', tags: 'professional campaign concept art-direction', image: 'images/tufts-childhood-disease/tufts_img.jpg', slug: 'tufts-childhood-disease' },
    { id: 8, title: 'Fun Sized', client: 'Rhode Island Tourism', tags: 'professional campaign art-direction', image: '/images/rhode-island-fun-sized/fun_sized_img.jpg', slug: 'rhode-island-fun-sized' },
    { id: 9, title: 'YoBaby', client: 'Stonyfield', tags: 'professional campaign concept art-direction', image: '/images/stonyfield-yobaby/stonyfield_img.jpg', slug: 'stonyfield-yobaby' },
    { id: 10, title: 'Give Well', client: 'Rhode Island Foundation', tags: 'design illustration', image: '/images/rif-together-ri/rif_img.jpg', slug: 'rif-together-ri' },
    { id: 11, title: 'Parks Photography', client: 'City of Providence', tags: 'photography', image: '/images/providence-parks/providence_img.jpg', slug: 'providence-parks' },
    { id: 13, title: 'Portraits', client: 'Nail', tags: 'photography', image: '/images/nail-portraits/nail_img.jpg', slug: 'nail-portraits' },
    { id: 14, title: 'Photography', client: 'Personal', tags: 'photography', image: '/images/personal-work/personal_img.jpg', slug: 'personal-work' },
    { id: 15, title: 'Boston Marathon', client: 'Gatorade Endurance', tags: 'art-direction campaign concept', image: '/images/gatorade-endurance/gatorade_img.jpg', slug: 'gatorade-endurance-boston-marathon' },
    { id: 20, title: 'Personal Injury MD', client: 'Timothy Tomasso', tags: 'unprofessional', image: '/images/timothy-tomasso-personal-injury-md/timothy_img.jpg', slug: 'timothy-tomasso-personal-injury-md' },
    { id: 21, title: 'Issues', client: 'The Rhode Island Post', tags: 'unprofessional', image: '/images/rhode-island-post/ripost_img.jpg', slug: 'rhode-island-post' },
  ];

  const lastTag = 'unprofessional';
  const tags = ['all', 'concept', 'art-direction', 'design', 'photography', 'illustration', 'copywriting', 'motion-graphics', lastTag];

  const filteredProjects = selectedTags.includes('all')
    ? projects.filter(project => !project.tags.includes(lastTag))
    : projects.filter(project => selectedTags.some(tag => project.tags.includes(tag)));

  return (
    <div>
      <Helmet>
        <title>Holland - Work</title>
        <meta name="description" content="The advertising work of Sam Holland, art director." />
        <meta name="keywords" content="art director, advertising, providence, boston, new england, northeast, hey even new york, freelance, graphic design, photography, motion graphics, dr timothy tomasso, etc" />
      </Helmet>
      <div class='category-tags'>
        {tags.map(tag => (
          <button
            key={tag}
            onClick={() => handleTagClick(tag)}
            className={selectedTags.includes(tag) ? 'active' : ''}
          >
            {tag.replace('-', ' ')}
          </button>
        ))}
      </div>
      <div class="workProjectsWrap">
      <ul className="work-projects">
        {filteredProjects.map((project, index) => (
          <li key={project.id} className={`home-project ${project.tags} project-${index + 1}`}>
            <Link to={`/work/${project.slug}`}>
              <img src={project.image} className="turnip" />
              <p>{project.title} <span>{project.client}</span></p>
            </Link>
          </li>
        ))}
      </ul>
      </div>
    </div>
  );
};

export default Work;