import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const Events = () => {
  const { slug } = useParams();
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    const filePath = `${process.env.PUBLIC_URL}/content/events/events.md`;

    fetch(filePath)
      .then((response) => response.text())
      .then((text) => setMarkdownContent(text))
      .catch((error) => console.error('It stopped working:', error));
  }, [slug]);
  useEffect(() => {
    
    
  }, [markdownContent]);

  return (

    <div>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={markdownContent} />
    </div>
    );
};

export default Events;
