import React, { useState, useEffect } from 'react';
import Overlay from './Overlay';
import '../library.css';

const LibraryPage = () => {
  const [links, setLinks] = useState([]);
  const [selectedLink, setSelectedLink] = useState(null);

  useEffect(() => {
    const fetchedLinks = [
      { id: 100, title: 'MTV Liquid Television - Episode 22', url: 'https://www.youtube.com/watch?v=saRm6oArftA', category: 'Animation' },
      
      { id: 103, title: 'Yuri Norstein - Yojik v Tumane', url: 'https://vimeo.com/10330419', category: 'Animation' },
      { id: 104, title: 'Simon Allen - Mother’s Peak', url: 'https://vimeo.com/326430509', category: 'Animation' },
      { id: 105, title: 'Vewn - Little Bunny', url: 'https://www.youtube.com/watch?v=wdYYI0QFlOU', category: 'Animation' },
      { id: 106, title: 'Vewn - Bad Kid Stuff', url: 'https://www.youtube.com/watch?v=UbXaiqA4ins', category: 'Animation' },
      { id: 115, title: 'Roman Kachanov - Cheburashka', url: 'https://www.youtube.com/watch?v=cU-_st6Ksac', category: 'Animation' },
      { id: 120, title: 'Fred Wolf with Harry Nilsson - The Point', url: 'https://www.youtube.com/watch?v=8shQK7vHpTQ', category: 'Animation' },
      { id: 207, title: 'Luis Buñuel - The Milky Way', url: 'https://www.youtube.com/watch?v=Z27RtlaxSFg', category: 'Foreign' },

      { id: 600, title: 'Neil Breen - Fateful Findings', url: '', category: 'Cult' },
      { id: 208, title: 'Jean-Luc Godard - Contempt', url: 'https://www.youtube.com/watch?v=j1fJsXQBJ60', category: 'Foreign' },
      { id: 301, title: 'John Flynn - Rolling Thunder', url: 'https://www.youtube.com/watch?v=FwAcWb5K3HM', category: 'Classics' },

      { id: 302, title: 'Bill Murray - Quick Change', url: 'https://www.youtube.com/watch?v=-KbWVp5kSpo', category: 'Classics' },
      { id: 909, title: 'Alfred Hitchcock - The Lodger', url: 'https://www.youtube.com/watch?v=bkkWQzfRaRE', category: 'Silent' },
      { id: 800, title: 'David Lynch - The Alphabet', url: 'https://www.youtube.com/watch?v=FCrKONTgtI8', category: 'Short Film'},
      { id: 914, title: 'Maya Deren - Meshes of the Afternoon', url: 'https://vimeo.com/218042283', category: 'Silent' },
      { id: 910, title: 'Buster Keaton - Sherlock Jr.', url: 'https://www.youtube.com/watch?v=fZuqWxITq38', category: 'Silent' },

      { id: 322, title: 'George Stevens - Shane', url: 'https://www.youtube.com/watch?v=BNxPwvwzZwk', category: 'Classics' },

      { id: 311, title: 'Ilya Kazan - Splendor in the Grass', url: 'https://www.youtube.com/watch?v=s-CKnxqdRZg', category: 'Classics'},

      { id: 344, title: 'Robert Altman - The Long Goodbye', url: 'https://www.youtube.com/watch?v=fAYheZweypk', category: 'Classics' },
      { id: 600, title: 'Night of the Hunter', url: '', category: 'Classics' },
      { id: 780, title: 'Mike Leigh - Life is Sweet', url: 'https://www.youtube.com/watch?v=WlHAGlq1Xrs', category: 'Foreign' },
      { id: 308, title: 'John Cassavetes - Husbands', url: 'https://www.youtube.com/watch?v=eLRBOMmKSLE', category: 'Classics' },

      { id: 799, title: 'Akira Kurosawa - Ran', url: 'https://www.youtube.com/watch?v=m4Sc32qDzXU', category: 'Foreign' },
      { id: 500, title: 'Chris Marker - Sans Soleil', url: 'https://www.youtube.com/watch?v=6Spi5glBm6Y', category: 'Documentary' },
      { id: 700, title: 'Oskar Schlemmer - Triadisches Ballett', url: 'https://www.youtube.com/watch?v=mHQmnumnNgo', category: 'Foreign'}
      




      // ... https://www.youtube.com/watch?v=WlHAGlq1Xrs
    ];

    setLinks(fetchedLinks);
  }, []);

  const handleLinkClick = link => {
    setSelectedLink(link.url);
  };

  const handleCloseOverlay = () => {
    setSelectedLink(null);
  };

  const groupedLinks = links.reduce((acc, link) => {
    if (!acc[link.category]) {
      acc[link.category] = [];
    }
    acc[link.category].push(link);
    return acc;
  }, {});

  return (
    <div class="libraryHome">
      {Object.keys(groupedLinks).map(category => (
        <div className={category} key={category}>
          <h2 className="library-movie-category">{category}</h2>
          <div className="library-shelf-wrap">
            <ul className="library-shelf">
              {groupedLinks[category].map(link => (
                <li className="libraryVideo" key={link.id}>
                  <a href="#" onClick={() => handleLinkClick(link)}>
                    <span>{link.title}</span> 
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
      {selectedLink && (
        <Overlay url={selectedLink} onClose={handleCloseOverlay} />
      )}
    </div>
  );
};


export default LibraryPage;