import React, { useEffect } from 'react';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';
const Overlay = ({ url, onClose }) => {
  const isYouTube = url.includes('youtube.com');
  const isVimeo = url.includes('vimeo.com');

  let videoElement = null;

  const aspectRatio = 9 / 16; // Set the aspect ratio of the video (e.g., 16:9)

  useEffect(() => {
    const handleResize = () => {
      const videoContainer = document.getElementById('video-container');
      if (videoContainer) {
        const containerWidth = videoContainer.offsetWidth;
        const videoHeight = containerWidth * aspectRatio;
        videoContainer.style.height = `${videoHeight}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [aspectRatio]);

  if (isYouTube) {
    const videoId = url.split('v=')[1];
    videoElement = (
      <div className="video-container">
        <YouTube videoId={videoId} opts={{ width: '100%', height: '100%' }} />
      </div>
    );
  } else if (isVimeo) {
    const videoId = url.split('vimeo.com/')[1];
    videoElement = (
      <div className="video-container">
        <ReactPlayer url={`https://vimeo.com/${videoId}`} controls width="100%" height="100%" />
      </div>
    );
  }

  return (
    <div className="overlay" onClick={onClose}>
      {videoElement}

      {/* Render the close button */}
      <button class="videoClose" onClick={onClose}> CLOSE </button>
    </div>
  );
};


export default Overlay;
