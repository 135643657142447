// src/components/ActiveRouteName.js
import React from 'react';
import { useLocation } from 'react-router-dom';

const routeNames = {
  '/': 'home',
  '/about': 'about',
  '/work': 'work',
  '/other': 'other',
  '/contact': 'contact',
};

const getRouteName = (pathname) => routeNames[pathname] || 'Unknown';

const ActiveRouteName = () => {
  const location = useLocation();
  const currentRouteName = getRouteName(location.pathname);

  return currentRouteName;
};

export default ActiveRouteName;