// src/components/Store.js
import React from 'react';
import { Link } from 'react-router-dom';

const Store = () => {
  const apparel = [
    { id: 1, title: 'Quahog Bites Man T-Shirt', slug: 'quahog-bites-man-t-shirt', image: 'https://via.placeholder.com/400x400.png?text=Item+1', description: 'lorem ipsum dolor sit amet', price: 20, tags: '' },
    { id: 2, title: 'The Notch T-Shirt', slug: 'the-notch-t-shirt', image: 'https://via.placeholder.com/400x400.png?text=Item+2', description: 'lorem ipsum dolor sit amet', price: 20, tags: '' },
    { id: 3, title: 'The Notch Belt', slug: 'the-notch-belt', image: 'https://via.placeholder.com/400x400.png?text=Item+3', description: 'lorem ipsum dolor sit amet', price: 20, tags: '' },
    { id: 4, title: 'Lands End Jacket, Lightly Worn', slug: 'lands-end-jacket-lightly-worn', image: 'https://via.placeholder.com/400x400.png?text=Item+4', description: 'lorem ipsum dolor sit amet', price: 60, tags: '' },
    { id: 5, title: 'Muji Plaid, Worn, Slightly Shrunken', slug: 'muji-plaid-slightly-shrunken', image: 'https://via.placeholder.com/400x400.png?text=Item+5', description: 'lorem ipsum dolor sit amet', price: 30, tags: '' },
  ];

  const womens = [
    { id: 1, title: 'Box of Dry Ice', slug: 'box-of-dry-ice', image: 'https://via.placeholder.com/400x400.png?text=Item+1', description: 'lorem ipsum dolor sit amet', price: 60, tags: '' },
    { id: 2, title: 'Wax Slab', slug: 'wax-slab', image: 'https://via.placeholder.com/400x400.png?text=Item+2', description: 'lorem ipsum dolor sit amet', price: 10, tags: '' },
    { id: 3, title: 'Metal Alligator', slug: 'metal-alligator', image: 'https://via.placeholder.com/400x400.png?text=Item+3', description: 'lorem ipsum dolor sit amet', price: 10, tags: '' },
  ];

  const gifts = [
    { id: 1, title: 'See You In Caught Bumper Sticker', slug: 'see-you-in-caught-bumper-sticker', image: 'https://via.placeholder.com/400x400.png?text=Item+1', description: 'lorem ipsum dolor sit amet', price: 8, tags: ''  },
    { id: 2, title: 'See You In Caught Mug', slug: 'see-you-in-caught-mug', image: 'https://via.placeholder.com/400x400.png?text=Item+2', description: 'lorem ipsum dolor sit amet', price: 15, tags: ''  },
    { id: 3, title: 'Ronald Santagata Poster', slug: 'ronald-santagata-poster', image: 'https://via.placeholder.com/400x400.png?text=Item+3', description: 'lorem ipsum dolor sit amet', price: 5, tags: ''  },
    { id: 4, title: 'Box Full of Papers', slug: 'box-full-of-papers', image: 'https://via.placeholder.com/400x400.png?text=Item+4', description: 'lorem ipsum dolor sit amet', price: 30, tags: '' },
    { id: 5, title: 'Drawing of Something', slug: 'drawing-of-something', image: 'https://via.placeholder.com/400x400.png?text=Item+5', description: 'lorem ipsum dolor sit amet', price: 15, tags: ''  },
    { id: 6, title: 'A Brand', slug: 'brand-design', image: 'https://via.placeholder.com/400x400.png?text=Item+5', description: 'lorem ipsum dolor sit amet', price: 15, tags: ''  },
  ];

  const periodicals = [
    { id: 1, title: 'Village Idiot', slug: 'village-idiot', image: 'https://via.placeholder.com/400x400.png?text=Item+1', description: 'lorem ipsum dolor sit amet', price: 3, tags: ''  },
    { id: 2, title: 'Rhode Island Post June 10th 2018 Issue', slug: 'rhode-island-post-june10-2018', image: 'https://via.placeholder.com/400x400.png?text=Item+2', description: 'lorem ipsum dolor sit amet', price: 5, tags: ''  },
    { id: 1, title: 'Brands of Rhode Island', slug: 'brands-of-rhode-island', image: 'https://via.placeholder.com/400x400.png?text=Item+1', description: 'lorem ipsum dolor sit amet', price: 40, tags: ''  },
  ]

  return (
    <div>
      <div class="store-wrap">
        <h3>Apparel</h3>
        <ul class="apparel">
          {apparel.map((item) => (
            <li key={item.id}>
              <Link to={`/store/${item.slug}`}><img src={item.image} /><br />
              {item.title}</Link>
              <p>&mdash;{item.price}</p>
            </li>
          ))}
        </ul>
        <h3>Everyday Luxuries</h3>
        <ul class="everyday">
          {gifts.map((item) => (
            <li key={item.id}>
              <Link to={`/store/${item.slug}`}><img src={item.image} /><br />
              {item.title}</Link>
              <p>&mdash;{item.price}</p>
            </li>
          ))}
        </ul>
        <h3>Gifts for Her</h3>
        <ul class="for-her">
          {womens.map((item) => (
            <li key={item.id}>
              <Link to={`/store/${item.slug}`}><img src={item.image} /><br />
              {item.title}</Link>
              <p>&mdash;{item.price}</p>
            </li>
          ))}
        </ul>
        <h3>Books &amp; Periodicals</h3>
        <ul class="periodicals">
          {periodicals.map((item) => (
            <li key={item.id}>
              <Link to={`/store/${item.slug}`}><img src={item.image} /><br />
              {item.title}</Link>
              <p>&mdash;{item.price}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Store;