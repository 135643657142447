import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ImageCanvas from './ImageCanvas.js';
import { Helmet } from 'react-helmet';

const Home = () => {

  const [className, setClassName] = useState('initial-class');

  const handleClick = () => {
    const classNames = ['class1', 'class2', 'class3'];
    const currentIndex = classNames.indexOf(className);
    const nextIndex = (currentIndex + 1) % classNames.length;
    setClassName(classNames[nextIndex]);
  };

  const homeProjects = [
    { id: 1, title: 'Rich Drama', client: 'Newport Mansions', image: '/images/psnc-rich-drama/psnc_img.jpg', slug: 'psnc-rich-drama', blurb: 'something something' },
    { id: 2, title: 'Help Someone Else', client: 'Rhode Island Blood Center', image: '/images/rhode-island-blood-center/ribc_img.jpg', slug: 'rhode-island-blood-center', blurb: '' },
    { id: 3, title: 'What We Get', client: 'Lifespan', image: '/images/lifespan-what-we-get/lifespan_img.jpg', slug: 'lifespan-what-we-get', blurb: '' },
    { id: 4, title: 'Don’t Tempt 2020', client: 'RI Department of Health', image: '/images/ridoh-dont-tempt-2020/ridoh_img.jpg', slug: 'ridoh-dont-tempt-2020', blurb: '' },
  ];
  const imagePaths = [
    '/images/home/holland2.svg'
  ];
  const replacementImages = [
    '/images/home/holland3.svg',
    '/images/home/holland30.svg',
    '/images/home/holland300.svg',
  ];

  const imageRefs = useRef([]);

  useEffect(() => {
    imageRefs.current = Array(homeProjects.length)
      .fill()
      .map((_, index) => imageRefs.current[index] || React.createRef());
  }, [homeProjects.length]);

  useEffect(() => {
    const homeProjectFade = () => {
      imageRefs.current.forEach((ref) => {
        if (ref.current) {
          const { top } = ref.current.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          const isVisible = top < windowHeight - 50; // Adjust the threshold as needed
          if (isVisible) {
            ref.current.classList.add('fade-in'); // Add a CSS class to trigger the fade-in animation
          }
        }
      });
    };

    window.addEventListener('scroll', homeProjectFade);

    return () => {
      window.removeEventListener('scroll', homeProjectFade);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Holland - Art Director</title>
        <meta name="description" content="Portfolio of Sam Holland, Art Director." />
        <meta name="keywords" content="art director, advertising, providence, boston, new england, northeast, hey even new york, freelance, graphic design, photography, motion graphics, dr timothy tomasso, etc" />
      </Helmet>
      <h1 class="invisible">Holland Land</h1>
      
      <div class="home-zero">
        <div className={`second-image ${className}`} onClick={handleClick}>

        </div>
        
      </div>
      <div class="homeProjectsWrap">
        <h2><Link to="/work">A few selected projects</Link></h2>
        <ul className="home-projects">
      {homeProjects.map((project, index) => (
        <li key={project.id} className={`home-project project-${index + 1} project-${project.slug}`}>
          <div className="homeWorkInner">
            <Link to={`/work/${project.slug}`}>
              <img ref={imageRefs.current[index]} src={project.image} className="turnip image" alt={project.title} />
              <div className="homePostText">
                <p className="homeProjectTitle">{project.client}</p>
                <span></span>
                <p className="homeBlurb">{project.title}</p>
              </div>
            </Link>
          </div>
        </li>
      ))}
    </ul>
        <h3 class="homeMoreWork"><Link to="/work"><span> --- See more ---</span></Link></h3>
      </div>


     
      
    </div>

  );
};

export default Home;
