import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const Item = () => {
  const { slug } = useParams();
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    const filePath = `${process.env.PUBLIC_URL}/content/store/${slug}.md`;

    fetch(filePath)
      .then((response) => response.text())
      .then((text) => setMarkdownContent(text))
      .catch((error) => console.error('It stopped working:', error));
  }, [slug]);

  useEffect(() => {
    const carouselContainer = document.querySelector('.carousel-container');

    if (carouselContainer) {
      const images = Array.from(carouselContainer.querySelectorAll('img'));
      const carousel = (
        <Carousel>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image.src} alt={image.alt} />
            </div>
          ))}
        </Carousel>
      );
      ReactDOM.render(carousel, carouselContainer);
    }

    const sizingOptions = document.querySelector('.sizing-options');

    if (sizingOptions) {
      const availablesizes = (
        <div>
          <p>Available Sizes</p>
          <button>XS</button>
          <button>S</button>
          <button>M</button>
          <button>L</button>
          <button>XL</button>
        </div>
      );
      ReactDOM.render(availablesizes, sizingOptions);
    }
  }, [markdownContent]);

  return (
    <div>
      <div className="projectWrap">
        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={markdownContent} />
      </div>
    </div>
  );
};

export default Item;
