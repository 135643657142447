import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-links">
      
        <div className="small-text">
          <p></p>
        </div>
      </div>
      
    </footer>
  );
};

export default Footer;